import * as React from "react";
import * as Mui from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import { DeleteSharp } from "@mui/icons-material";
import { indexes } from "../redux/reducers/mainReducer";
import stc from "string-to-color";
import useWebSocket from "react-use-websocket";

import {
  getAllBots,
  createBot,
  setState,
  setBotHistory,
  deleteBot,
} from "../redux/reducers/botReducer";
import toast from "react-hot-toast";
import BotHistoryChart from "./BotHistoryChart";

const addModelStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 700 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

// const SERVER_SOCKET_API_URL_LOCAL = "ws://localhost:5000/ws/bot";
// const SERVER_SOCKET_BOT_HISTORY_API_URL_LOCAL =
//   "ws://localhost:5000/ws/bot/history";
const SERVER_SOCKET_API_URL_DEPLOY =
  "wss://deardrphil-stock-server.herokuapp.com/ws/bot";
const SERVER_SOCKET_BOT_HISTORY_API_URL_DEPLOY =
  "wss://deardrphil-stock-server.herokuapp.com/ws/bot/history";

export default function Bot(props) {
  const dispatch = useDispatch();
  const bots = useSelector((state) => state.bot.bots);
  const botHistory = useSelector((state) => state.bot.botHistory);
  const [index1, setIndex1] = React.useState(indexes[0].no);
  const [index2, setIndex2] = React.useState(indexes[0].no);
  const [percent, setPercent] = React.useState(10);
  const [takeProfitLimit, setTakeProfitLimit] = React.useState(10);
  const [addModelOpen, setAddModelOpen] = React.useState(false);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] =
    React.useState(false);
  const [currentSelectedBotIdForDeletion, setCurrentSelectedBotIdForDeletion] =
    React.useState(null);
  const socketOfBotHistory = useWebSocket(
    SERVER_SOCKET_BOT_HISTORY_API_URL_DEPLOY,
    {
      onOpen: () => console.log("WebSocket of bot history connection opened"),
      onClose: () => console.log("WebSocket of bot history connection closed"),
      onError: (event) =>
        console.error("WebSocket of bot history error:", event),
    }
  );
  const socketOfBot = useWebSocket(SERVER_SOCKET_API_URL_DEPLOY, {
    onOpen: () => console.log("WebSocket connection opened"),
    onClose: () => console.log("WebSocket connection closed"),
    onError: (event) => console.error("WebSocket error:", event),
  });

  const pairs = indexes.reduce(
    (acc, first, i) =>
      acc.concat(indexes.slice(i + 1).map((second) => ({ first, second }))),
    []
  );

  const handleAddModelOpen = React.useCallback(() => {
    setAddModelOpen(true);
  }, []);

  const handleAddModelClose = React.useCallback(() => {
    setAddModelOpen(false);
  }, []);

  const handleIndex1Change = React.useCallback((e) => {
    setIndex1(e.target.value);
  }, []);

  const handleIndex2Change = React.useCallback((e) => {
    setIndex2(e.target.value);
  }, []);

  const handlePercentChange = React.useCallback((e) => {
    setPercent(e.target.value);
  }, []);

  const handleTakeProfitLimitChange = React.useCallback((e) => {
    setTakeProfitLimit(e.target.value);
  }, []);

  const handleDeleteDialogOpen = React.useCallback(() => {
    setDeleteConfirmDialogOpen(true);
  }, []);

  const handleDeleteDialogClose = React.useCallback(() => {
    setDeleteConfirmDialogOpen(false);
  }, []);

  const handleCreateBot = React.useCallback(() => {
    try {
      const pairID = pairs.findIndex(
        (pair) =>
          (pair.first.symbol === indexes[index1].symbol &&
            pair.second.symbol === indexes[index2].symbol) ||
          (pair.second.symbol === indexes[index1].symbol &&
            pair.first.symbol === indexes[index2].symbol)
      );

      if (pairID === -1) {
        throw new Error("Please select proper pair");
      }

      dispatch(
        createBot({
          pairId: pairID,
          percent: percent,
          takeProfitLimit: takeProfitLimit,
        })
      );

      toast.success("Bot has been created successfully!");
      handleAddModelClose();
    } catch (e) {
      toast.error("Please select proper pair");
    }
  }, [
    index1,
    index2,
    pairs,
    percent,
    takeProfitLimit,
    dispatch,
    handleAddModelClose,
  ]);

  const calculateMaximumDrawdown = React.useCallback(
    (bot) => {
      const history = botHistory.find((history) => history.tradeId === bot.id);

      let seriesData = [];

      if (history) {
        seriesData = history.series.map((data) => data[1]) ?? [];
      }

      seriesData = seriesData.filter((value) => {
        const numValue = Number(value);
        return !isNaN(numValue);
      });

      const maxDrawdown = seriesData.reduce((min, value) => {
        return Math.min(min, value);
      }, Infinity);

      return maxDrawdown;
    },
    [botHistory]
  );

  const handleDelete = React.useCallback(() => {
    dispatch(deleteBot({ botId: currentSelectedBotIdForDeletion }));

    handleDeleteDialogClose();
  }, [currentSelectedBotIdForDeletion, handleDeleteDialogClose, dispatch]);

  React.useEffect(() => {
    dispatch(getAllBots());
  }, [dispatch]);

  React.useEffect(() => {
    if (socketOfBot.lastMessage !== null) {
      dispatch(setState(JSON.parse(socketOfBot.lastMessage.data)));
    }
  }, [socketOfBot.lastMessage, dispatch]);

  React.useEffect(() => {
    if (socketOfBotHistory.lastMessage !== null) {
      dispatch(setBotHistory(JSON.parse(socketOfBotHistory.lastMessage.data)));
    }
  }, [socketOfBotHistory.lastMessage, dispatch]);

  return (
    <Mui.Box>
      <Navbar />

      <Mui.Container
        sx={{ marginTop: 4, display: "flex", justifyContent: "end" }}
      >
        <Mui.Button variant="contained" onClick={handleAddModelOpen}>
          + New
        </Mui.Button>
      </Mui.Container>

      <Mui.Container maxWidth="1600" sx={{ marginTop: 4 }}>
        <Mui.Grid container spacing={2}>
          {bots.map((bot) => (
            <Mui.Grid key={bot.id} item xs={12} sm={6}>
              <Mui.Card>
                <Mui.CardHeader
                  avatar={
                    <Mui.Avatar
                      sx={{
                        bgcolor: stc(
                          pairs[bot.pairId].first.symbol +
                            pairs[bot.pairId].second.symbol
                        ),
                      }}
                      aria-label="recipe"
                    >
                      {pairs[bot.pairId].first.symbol[2] +
                        pairs[bot.pairId].second.symbol[2]}
                    </Mui.Avatar>
                  }
                  action={
                    <Mui.IconButton aria-label="settings">
                      <DeleteSharp
                        color="warning"
                        onClick={() => {
                          setCurrentSelectedBotIdForDeletion(bot.id);
                          handleDeleteDialogOpen();
                        }}
                      />
                    </Mui.IconButton>
                  }
                  title={`${pairs[bot.pairId].first.symbol} and ${
                    pairs[bot.pairId].second.symbol
                  }`}
                  subheader={`${new Date(bot.date).toLocaleDateString(
                    undefined,
                    dateOptions
                  )} Opening intervals: ${bot.percent}% Take profit Limit: ${
                    bot.takeProfitLimit
                  }%`}
                />
                <Mui.CardContent>
                  <Mui.Container
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Mui.Typography>
                      Total Net Profit/Loss:{" "}
                      {bot.positions
                        .reduce((acc, cur) => acc + cur.netProfit, 0)
                        .toFixed(2)}{" "}
                      {`($)`}
                    </Mui.Typography>
                    <Mui.Typography>
                      Maximum Drawdown:{" "}
                      {calculateMaximumDrawdown(bot).toFixed(2)} {`($)`}
                    </Mui.Typography>

                    <Mui.Typography>
                      Capital Deployed: {10000 * bot.positions.length} {`($)`}
                    </Mui.Typography>

                    <Mui.Typography>
                      Return on Investment pa:{" "}
                      {(
                        (bot.positions.reduce(
                          (acc, cur) => acc + cur.netProfit,
                          0
                        ) /
                          (10000 * bot.positions.length)) *
                        100
                      ).toFixed(2)}{" "}
                      {"%"}
                    </Mui.Typography>
                  </Mui.Container>

                  <BotHistoryChart tradeId={bot.id} />

                  <Mui.TableContainer component={Mui.Paper}>
                    <Mui.Table sx={{ padding: 1 }} aria-label="positions table">
                      <Mui.TableHead>
                        <Mui.TableRow>
                          <Mui.TableCell>No</Mui.TableCell>
                          <Mui.TableCell>Date</Mui.TableCell>
                          <Mui.TableCell align="right">
                            Net Profit/Loss
                          </Mui.TableCell>
                          <Mui.TableCell align="right">Size1</Mui.TableCell>
                          <Mui.TableCell align="right">Type1</Mui.TableCell>
                          <Mui.TableCell align="right">
                            Opening Price1
                          </Mui.TableCell>
                          <Mui.TableCell align="right">Size2</Mui.TableCell>
                          <Mui.TableCell align="right">Type2</Mui.TableCell>
                          <Mui.TableCell align="right">
                            Opening Price2
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </Mui.TableHead>

                      <Mui.TableBody>
                        {bot.positions.map((position) => (
                          <Mui.TableRow key={position.id}>
                            <Mui.TableCell align="right">
                              {position.no}
                            </Mui.TableCell>
                            <Mui.TableCell align="right">
                              {new Date(position.date).toLocaleDateString()}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {position.netProfit.toFixed(2)}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {position.size1.toFixed(2)}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              <Mui.Chip
                                label={position.type1 === 0 ? "Long" : "Short"}
                                color={
                                  position.type1 === 0 ? "primary" : "error"
                                }
                              />
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {position.openingPrice1.toFixed(2)}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {position.size2.toFixed(2)}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              <Mui.Chip
                                label={position.type2 === 0 ? "Long" : "Short"}
                                color={
                                  position.type2 === 0 ? "primary" : "error"
                                }
                              />
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {position.openingPrice2.toFixed(2)}
                            </Mui.TableCell>
                          </Mui.TableRow>
                        ))}
                      </Mui.TableBody>
                    </Mui.Table>
                  </Mui.TableContainer>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
          ))}
        </Mui.Grid>
      </Mui.Container>

      <Mui.Modal
        open={addModelOpen}
        onClose={handleAddModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Mui.Box sx={addModelStyle}>
          <Mui.Typography variant="h4" sx={{ textAlign: "center" }}>
            ADD NEW BOT
          </Mui.Typography>

          <Mui.Container
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Mui.Grid container spacing={2}>
              <Mui.Grid item xs={6}>
                {/* Index 1 */}
                <Mui.FormControl
                  fullWidth
                  sx={{ m: 1, minWidth: 120 }}
                  size="small"
                >
                  <Mui.InputLabel id="demo-simple-select-label">
                    Index 1
                  </Mui.InputLabel>
                  <Mui.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={index1}
                    label="Index 1"
                    onChange={handleIndex1Change}
                  >
                    {indexes.map((index, key) => {
                      return (
                        <Mui.MenuItem value={index.no} key={key}>
                          {index.symbol}
                        </Mui.MenuItem>
                      );
                    })}
                  </Mui.Select>
                </Mui.FormControl>
              </Mui.Grid>
              <Mui.Grid item xs={6}>
                {/* Index 2 */}
                <Mui.FormControl
                  fullWidth
                  sx={{ m: 1, minWidth: 120 }}
                  size="small"
                >
                  <Mui.InputLabel id="demo-simple-select-label">
                    Index 2
                  </Mui.InputLabel>
                  <Mui.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={index2}
                    label="Index 2"
                    onChange={handleIndex2Change}
                  >
                    {indexes.map((index, key) => {
                      return (
                        <Mui.MenuItem value={index.no} key={key}>
                          {index.symbol}
                        </Mui.MenuItem>
                      );
                    })}
                  </Mui.Select>
                </Mui.FormControl>
              </Mui.Grid>
            </Mui.Grid>

            {/* Percent */}
            <Mui.TextField
              id="outlined"
              label="Percent of Notional Value (%)"
              size="small"
              type="number"
              sx={{
                marginLeft: 1,
                marginRight: -1,
              }}
              value={percent}
              onChange={handlePercentChange}
            />

            {/* TakeProfitLimit */}
            <Mui.TextField
              id="outlined"
              label="The Limit of taking profit (%)"
              size="small"
              type="number"
              sx={{
                marginLeft: 1,
                marginRight: -1,
              }}
              value={takeProfitLimit}
              onChange={handleTakeProfitLimitChange}
            />

            <Mui.Container
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <Mui.Button variant="contained" onClick={handleCreateBot}>
                Create
              </Mui.Button>
            </Mui.Container>
          </Mui.Container>
        </Mui.Box>
      </Mui.Modal>

      {/* Delete confirmation dialog */}
      <Mui.Dialog
        open={deleteConfirmDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {"Do you want to delete this trade?"}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">
            Please make sure that you are gonna delete this trade!
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button color="error" onClick={handleDeleteDialogClose}>
            Disagree
          </Mui.Button>
          <Mui.Button color="success" onClick={() => handleDelete()} autoFocus>
            Agree
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </Mui.Box>
  );
}
